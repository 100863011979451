import Markdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useRef, useEffect, useCallback } from 'react';

// Custom Hooks Imports
import useChat from 'hooks/useChat';
import useAuth from 'hooks/useAuth';


// Component Imports
import Avatar from 'components/@extended/Avatar';

// MUI Imports
import {
    Box,
    Grid,
    List,
    Paper,
    Button,
    ListItem,
    Skeleton,
    Container,
    TextField,
    Typography,
    IconButton,
    ListItemText,
    useMediaQuery,
    CircularProgress,
    DialogContent,
    DialogActions,
    Divider,
} from '@mui/material';
import { Stack, styled, useTheme } from '@mui/system';

// Image Imports
import sapphireImage from 'assets/images/sapphire_logo.jpg';

// Icon Imports
import { Add, HambergerMenu, Send2, Trash } from 'iconsax-react';

// Redux Imports
import { resetRelatedStatesOnRoomChange, setChatSkip, setCurrentChatRoom } from 'store/reducers/chat';
import { Dialog } from '@mui/material';
import { DialogTitle } from '@mui/material';
import { DialogContentText } from '@mui/material';

import { PopupTransition } from 'components/@extended/Transitions';

const Sidebar = styled(Box)(({ theme }) => ({
    width: 300,
    borderRight: `1px solid ${theme.palette.divider}`,
    height: "100%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
}));


const SkeletonLoader = () => {
    const theme = useTheme();
    return (
        <Stack display={"flex"} flexDirection={"row"} gap={1}>
            <Avatar
                alt={'AI Image'}
                src={sapphireImage}
                sx={{
                    width: 34,
                    height: 34,
                    border: '1px solid',
                    borderColor: theme.palette.primary.main,
                    bgcolor: 'transparent',
                    objectFit: 'contain',
                    mt: '4px' // Small margin to align better with text
                }}
            />
            <Paper
                elevation={1}
                sx={{
                    p: 2,
                    bgcolor: '#FFFFFF',
                    color: 'text.primary',
                    borderRadius: 2,
                    width: '81%',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                    
                    {/* <Skeleton variant="circular" width={34} height={34} /> */}

                    {/* Message Skeletons */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                    </Box>
                </Box>
            </Paper>
        </Stack>
    );
};

const YouTubePreview = ({ url }: { url: string }) => {
    // const videoId = getYouTubeVideoId(url);

    // if (!videoId) return null;

    return (
        <Box sx={{
            width: 280,
            flexShrink: 0
        }}>
            <Box sx={{
                position: 'relative',
                paddingBottom: '56.25%',
                height: 0,
                overflow: 'hidden',
                borderRadius: 1
            }}>
                <iframe
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        border: 0
                    }}
                    // src={`https://www.youtube.com/embed/${videoId}`}
                    src={url}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="YouTube Video Preview"
                />
            </Box>
        </Box>
    );
};

const MessageContent = ({ text, youtubeUrls }: { text: string, youtubeUrls: any }) => {
    const isMarkdownFormat = /(\*\*.+\*\*:)|(\* .+)/.test(text);
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {/* Text content */}
            {isMarkdownFormat ? (
                <Markdown>{text}</Markdown>
            ) : (
                <Typography>{text}</Typography>
            )}

            {/* YouTube videos container */}
            {youtubeUrls && youtubeUrls.length > 0 && (
                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    overflowX: 'auto',
                    pb: 1,
                    '&::-webkit-scrollbar': {
                        height: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                        background: '#f1f1f1',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: '#888',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        background: '#555',
                    },
                }}>
                    {youtubeUrls.map((url: string, index: number) => (
                        <YouTubePreview key={index} url={url} />
                    ))}
                </Box>
            )}
        </Box>
    );
};

const AIChatBot = () => {

    const avatarImage = (require as any).context('assets/images/users', true);

    // Style related Hooks
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    // Custom Hooks
    const { user } = useAuth()
    const { createAIChatRoom, getAllAIChatRooms, sendMessageToAI, getAIRoomChats, deleteRoom } = useChat();

    // Redux Hooks
    const dispatch = useDispatch();

    // Ref Hooks
    const isInitialLoad = useRef(false);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    // Redux States
    const skip = useSelector((state: any) => state.chat.skip)
    const AILoading = useSelector((state: any) => state.chat.AILoading)
    const roomList = useSelector((state: any) => state.chat.aiChatRooms)
    const hasMoreChat = useSelector((state: any) => state.chat.hasMoreChat)
    const chatMessages = useSelector((state: any) => state.chat.aiChatHistory)
    const currentRoom = useSelector((state: any) => state.chat.currentChatRoom)

    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [roomIdToDelete, setRoomIdToDelete] = useState('');
    const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);


    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const getRoomChats = async () => {
        if (!hasMoreChat) return;

        try {
            if (chatMessages?.length > 0) {
                let newSkip = skip + 1
                dispatch(setChatSkip(newSkip))
                setLoading(true);
                await getAIRoomChats(currentRoom?.roomId, newSkip);
                setLoading(false)
            } else {
                setLoading(true)
                await getAIRoomChats(currentRoom?.roomId);
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.log("Error in getting room chats: ", error)
        }
    }

    const handleNewChat = async () => {
        createAIChatRoom({ roomName: "AI Chat " + Date.now() })
    };

    const handleSendMessage = async () => {
        sendMessageToAI({ 
            message: message, 
            updateRoomName: chatMessages && chatMessages.length > 0 ? false : true 
        })
        setMessage("")

        // Delay scrolling to ensure the message is rendered
        setTimeout(() => {
            scrollToBottom();
        }, 100);
    };

    // Debounce function
    const debounce = (func: Function, wait: number) => {
        let timeout: NodeJS.Timeout;

        return (...args: any[]) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        };
    };

    // In your component:
    const debouncedHandleScroll = useCallback(
        debounce(() => {
            const container = containerRef.current;
            if (!container) return;

            const scrollPosition = container.scrollTop;
            const maxScroll = container.scrollHeight - container.clientHeight;
            const scrollPercentage = (scrollPosition / maxScroll) * 100;

            if (scrollPercentage < 10 && !loading && hasMoreChat) {
                getRoomChats();
            }
        }, 150),
        [loading, hasMoreChat, skip, chatMessages]
    );

    const renderSidebar = (
        <Sidebar
            sx={{
                width: 300, // Fixed width
                height: "80vh", // Full height
                overflowY: "auto",
                padding: "0px !important",
            }}
        >
            <Box>
                <Stack display={"flex"} flexDirection={"row-reverse"}>
                    <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
                        <HambergerMenu />
                    </IconButton>
                </Stack>
                <IconButton onClick={handleNewChat} color="primary" sx={{ width: "100%", mb: 2 }}>
                    <Add /> <Typography sx={{ ml: 1 }}>New Chat</Typography>
                </IconButton>
            </Box>
            <List
                sx={{
                    maxHeight: "calc(80vh - 100px)", // Adjust height to fit within sidebar
                    overflowY: "auto", // Enable scrolling for the list
                }}
            >
                {roomList.map((chat: any) => (
                    <ListItem
                        key={chat.id}
                        onClick={() => {
                            dispatch(resetRelatedStatesOnRoomChange())
                            dispatch(setCurrentChatRoom(chat))
                        }}
                        sx={{
                            cursor: "pointer",
                            background: currentRoom?._id === chat._id ? "#e8f0fe" : "transparent",
                            color: currentRoom?._id === chat._id ? "black" : "inherit",
                            "&:hover": {
                                bgcolor: currentRoom?.id === chat.id ? "action.hover" : "#c7d9fa", // Adjusted hover effect
                            },
                        }}
                        secondaryAction={
                            <IconButton
                                edge="end"
                                color='error'
                                onClick={(e) => {
                                    setRoomIdToDelete(chat?.roomId)
                                    setIsOpenDeleteDialog(true)
                                    e.stopPropagation();
                                }}
                            >
                                <Trash />
                            </IconButton>
                        }
                    >
                        <ListItemText
                            primary={chat?.roomName}
                            secondary={new Date(chat.createdAt).toLocaleString()}
                        />
                    </ListItem>
                ))}
            </List>
        </Sidebar>
    );

    const handleDeleteRoom = async () => {
        deleteRoom(roomIdToDelete)
        setIsOpenDeleteDialog(false)
    };

    useEffect(() => {
        if (!isInitialLoad.current && chatMessages.length > 0) {
            messagesEndRef.current?.scrollIntoView();
            isInitialLoad.current = true; // Set flag to true after first execution
        }
    }, [chatMessages]);

    useEffect(() => {
        getAllAIChatRooms()
    }, [])

    useEffect(() => {
        currentRoom && getRoomChats()
        isInitialLoad.current = false;
    }, [currentRoom])

    return (
        <>
            <Dialog
                open={isOpenDeleteDialog}
                TransitionComponent={PopupTransition}
            >
                <DialogTitle>
                    <Typography
                        fontWeight={600}
                        fontSize={"18px"}
                    >
                        Are you sure you want to delete this conversation?
                    </Typography>
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <DialogContentText>
                        This action will permanently erase all messages in this conversation, and you will not be able to recover them. Are you sure you want to proceed with the deletion?
                    </DialogContentText>
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button 
                        autoFocus
                        sx={{
                            m: 1,
                            fontSize: { xs: "12px", sm: "14px", },
                            padding: { xs: "4px 8px", sm: "6px 12px", },
                        }}
                        color="primary"
                        variant="contained"
                        onClick={() => setIsOpenDeleteDialog(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        autoFocus
                        sx={{
                            px: 3,
                            fontSize: { xs: "12px", sm: "14px", },
                            padding: { xs: "3.5px 10px", sm: "4.5px 10px", },
                            ml: 1,
                            ":hover": {
                                color: "white",
                                backgroundColor: "#dc2626"
                            },
                        }}
                        color="error"
                        variant="outlined"
                        onClick={() => handleDeleteRoom()}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Grid display={"flex"} flexDirection={"row"}>
                <Grid item xs={2}>
                    {
                        drawerOpen
                            ? renderSidebar
                            : <IconButton
                                onClick={() => setDrawerOpen(!drawerOpen)}
                            >
                                <HambergerMenu />
                            </IconButton>
                    }
                </Grid>
                <Grid item xs={10} sx={{ width: "100%" }}>
                    <Box sx={{
                        height: '80vh',
                        width: "100%",
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden'
                    }}>
                        <Box
                            ref={containerRef}
                            onScroll={debouncedHandleScroll}
                            sx={{
                                flexGrow: 1,
                                overflowY: 'auto',
                                bgcolor: '#f0f0f0',
                                p: 2,
                                height: 'calc(100vh - 128px)',
                                '&::-webkit-scrollbar': {
                                    width: '8px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    background: '#f1f1f1',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    background: '#888',
                                    borderRadius: '4px',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    background: '#555',
                                },
                            }}
                        >
                            <Container sx={{ height: '100%', padding: "0px !important" }}>
                                {loading && (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                                        <CircularProgress size={24} />
                                    </Box>
                                )}

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                    minHeight: '100%'
                                }}>
                                    {chatMessages?.map((msg: any, index: number) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: msg.isUser ? 'flex-end' : 'flex-start',
                                                alignItems: 'flex-start',
                                                maxWidth: '85%',
                                                alignSelf: msg.isUser ? 'flex-end' : 'flex-start',
                                                gap: 1
                                            }}
                                        >
                                            {/* Avatar for non-user messages (left side) */}
                                            {!msg.isUser && (
                                                <Avatar
                                                    alt={user?.firstName || 'User Name'}
                                                    src={sapphireImage}
                                                    sx={{
                                                        width: 34,
                                                        height: 34,
                                                        border: '1px solid',
                                                        borderColor: theme.palette.primary.main,
                                                        bgcolor: 'transparent',
                                                        objectFit: 'contain',
                                                        mt: '4px'
                                                    }}
                                                />
                                            )}

                                            {/* Message Content */}
                                            <Paper
                                                elevation={1}
                                                sx={{
                                                    p: 2,
                                                    bgcolor: msg.isUser ? 'primary.main' : '#FFFFFF',
                                                    color: msg.isUser ? 'primary.contrastText' : 'text.primary',
                                                    borderRadius: 2,
                                                    width: '100%'
                                                }}
                                            >
                                                <MessageContent text={msg.text} youtubeUrls={msg.ytLinks} />
                                            </Paper>

                                            {/* Avatar for user messages (right side) */}
                                            {msg.isUser && (
                                                <Avatar
                                                    alt={user?.firstName || 'User Name'}
                                                    src={
                                                        typeof user?.userProfilePicId !== 'string' && user?.userProfilePicId?.path
                                                            ? process.env.REACT_APP_FILE_URL + user?.userProfilePicId?.path
                                                            : typeof user?.userProfilePicId !== 'string' && user?.userProfilePicId?.mimetype
                                                                ? `data:${user?.userProfilePicId?.mimetype};base64,${user?.userProfilePicId?.file}`
                                                                : avatarImage
                                                    }
                                                    sx={{
                                                        width: 34,
                                                        height: 34,
                                                        border: '1px solid',
                                                        borderColor: theme.palette.primary.main,
                                                        bgcolor: 'transparent',
                                                        objectFit: 'contain',
                                                        mt: '4px'
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    ))}
                                    {
                                        AILoading &&
                                        <SkeletonLoader />
                                    }
                                    <div ref={messagesEndRef} />
                                </Box>
                            </Container>
                        </Box>

                        {currentRoom && (
                            <Box
                                sx={{
                                    p: 1,
                                    bgcolor: '#FFFFFF',
                                    borderTop: 1,
                                    borderColor: 'divider'
                                }}
                            >
                                <Container sx={{ padding: "0px !important" }}>
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        <TextField
                                            variant="outlined"
                                            placeholder="Type your message..."
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            onKeyPress={(e) => {
                                                e.key === "Enter" && handleSendMessage()

                                            }}
                                            sx={{ backgroundColor: 'background.paper', width: "90%" }}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                handleSendMessage()
                                            }}
                                            disabled={!message.trim()}
                                        >
                                            Send&nbsp;<Send2 size={40} variant="Bold" />
                                        </Button>
                                    </Box>
                                </Container>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default AIChatBot;