import { useEffect, useRef, useState, KeyboardEvent, MouseEvent, useCallback } from 'react';

// material-ui
import { useTheme, styled, Theme } from '@mui/material/styles';
import { Box, Button, Chip, ClickAwayListener, Collapse, Dialog, Grid, Popper, Stack, TextField, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
// third-party
import EmojiPicker, { SkinTones, EmojiClickData } from 'emoji-picker-react';

// project-imports
import ChatDrawer from 'sections/apps/chat/ChatDrawer';
import ChatHistory from 'sections/apps/chat/ChatHistory';
import UserDetails from 'sections/apps/chat/UserDetails';

import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import SimpleBar from 'components/third-party/SimpleBar';
import { PopupTransition } from 'components/@extended/Transitions';

import { dispatch, useSelector } from 'store';
import { openDrawer } from 'store/reducers/menu';
import pdfIcon from 'assets/images/fileIcons/OF1HA11-ai (2).png';
import textIcon from 'assets/images/fileIcons/OF1HA11-ai (14).png';
import docIcon from 'assets/images/fileIcons/OF1HA11-ai (8).png';
import pptIcon from 'assets/images/fileIcons/OF1HA11-ai (1).png';
// import docxIcon from 'assets/images/fileIcons/OF1HA11-ai (8).png';
// import gifIcon from 'assets/images/fileIcons/OF1HA11-ai (10).png';
import otherIcon from 'assets/images/pdf-file_9680524.png';
import sapphireImage from 'assets/images/sapphire_logo.jpg';
// assets
import { Add, AddCircle, AttachCircle, CloseCircle, EmojiHappy, HambergerMenu, InfoCircle, Send, UserSearch } from 'iconsax-react';

// types
import useChat from 'hooks/useChat';
import useAuth from 'hooks/useAuth';
import Avatar from 'components/@extended/Avatar';
import { resetStateChat, resetTyping, setChatMessage, setUnreadChip, setUserChatId } from 'store/reducers/chat';
import Snackbar from 'utils/Snackbar';
import Loader2 from 'components/Loader2';
import WaveAnimationDot from 'components/waveAnimationDot';
import { FormattedMessage } from 'react-intl';

const drawerWidth = 320;
const avatarImage = (require as any).context('assets/images/users', true);

const Main = styled('main', { shouldForwardProp: (prop: string) => prop !== 'open' })(
  ({ theme, open }: { theme: Theme; open: boolean }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter
    }),
    marginLeft: `-${drawerWidth}px`,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 0,
      marginLeft: 0
    },
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shorter
      }),
      marginLeft: 0
    })
  })
);

const debounce = (func: any, delay: number) => {
  let timeoutId: any;
  return (...args: any) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

// ==============================|| APPLICATION - CHAT ||============================== //

const Chat = () => {
  const { user } = useAuth();
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('lg'));
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));
  const [isTyping, setIsTyping] = useState(false);
  const {
    currentUser,
    userChats,
    activeUser,
    userSwitch,
    userRoomId,
    userChatId,
    chatMessage,
    AiRoomId,
    getRoomUsers,
    createChat,
    updateChat,
    sendIsTyping
  } = useChat();

  const [anchorElEmoji, setAnchorElEmoji] = useState<Element | null>(null);
  const [userDetailsPopOver, setUserDetailsPopOver] = useState(false);
  const [openUserDrawer, setOpenUserDrawer] = useState(true);
  const [imageData, setImageData] = useState<Array<any>>([]);
  const emojiOpen = Boolean(anchorElEmoji);
  const emojiId = emojiOpen ? 'simple-popper' : undefined;
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down(1264));

  const handleUserPopOverChange = () => {
    setUserDetailsPopOver((prev) => !prev);
  };

  const handleUserDrawerChange = () => {
    setOpenUserDrawer((prev) => !prev);
  };

  const handleOnEmojiButtonClick = (event: MouseEvent<HTMLButtonElement> | undefined) => {
    setAnchorElEmoji(anchorElEmoji ? null : event?.currentTarget ? event?.currentTarget : null);
  };

  const onEmojiClick = (emojiObject: EmojiClickData) => {
    const chatString = `${chatMessage || ''}${emojiObject.emoji}`;
    dispatch(setChatMessage(chatString));
  };

  const handleCloseEmoji = () => {
    setAnchorElEmoji(null);
  };
  const aiChatLoading = useSelector((state) => state.chat).AILoading;
  const textInput = useRef<HTMLInputElement>(null);

  const FocusTextInput = (chatText: any) => {
    dispatch(setChatMessage(chatText));
    textInput.current?.focus();
  };
  const fileTypeToIcon = {
    'application/pdf': pdfIcon,
    'application/msword': otherIcon,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': docIcon,
    'text/plain': textIcon,
    'application/vnd.ms-powerpoint': pptIcon,
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': pptIcon
  } as const;

  type FileType = keyof typeof fileTypeToIcon;

  const getIconByFileType = (fileType: FileType): string | boolean => {
    return fileTypeToIcon[fileType] || false;
  };
  const handleFileChange = async (event: any) => {
    // const files = Array.from(event.target.files);

    const input = event.currentTarget;

    if (input.files && input.files.length > 0) {
      const files = Array.from(input.files);

      const totalSize: any = files.reduce((acc, file: any) => acc + file.size, 0);

      const MAX_SIZE = 10 * 1024 * 1024;
      if (totalSize > MAX_SIZE) {
        Snackbar('Total file size exceeds 10 MB. Please upload smaller files.', 'error');
        return;
      }

      const readFile = (file: any) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const arrayBuffer = reader.result;
            if (arrayBuffer instanceof ArrayBuffer) {
              const buffer = Buffer.from(new Uint8Array(arrayBuffer));
              const base64String = btoa(new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
              const fileData = {
                fileName: file.name,
                mimetype: file.type,
                data: buffer,
                preview: `data:${file.type};base64,${base64String}` // For image preview
              };
              resolve(fileData);
            } else {
              reject(new Error('Error reading file: result is not an ArrayBuffer'));
            }
          };
          reader.onerror = reject;
          reader.readAsArrayBuffer(file);
        });
      };

      try {
        const results = await Promise.all(files.map(readFile));
        setImageData(results);
      } catch (error) {
        console.error('Error reading files:', error);
      }
    }
  };

  const removeImage = (index: any) => {
    setImageData(imageData.filter((_, i) => i !== index));
  };

  const excludePreviewField = (data: any) => {
    return data.map(({ preview, ...rest }: any) => rest);
  };

  const handleOnSend = async () => {
    const filesWithoutPreview = excludePreviewField(imageData);

    if (!currentUser) return;

    try {
      dispatch(setChatMessage(null));

      if (userChatId !== null && !currentUser?.isAIChat) {
        updateChat(userChatId as string, { text: chatMessage });
      } else {
        let createData: any = {};
        if (currentUser?.isAIChat) {
          createData.isAIChat = true;
        }
        if (chatMessage?.trim() !== '') {
          createData.text = chatMessage;
        }
        if (filesWithoutPreview && filesWithoutPreview.length > 0) {
          createData.files = filesWithoutPreview;
        }
        const createdChat = await createChat(userRoomId, createData);
      }
      dispatch(setUnreadChip(false));
      dispatch(setUserChatId(null));
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleButtonClick = () => {
    const fileInput = document.getElementById('contained-button-file1');
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleStopTyping = () => {
    setIsTyping(false);
    if (user?._id && user?.type) sendIsTyping(user._id, currentUser?._id, user?.type, currentUser?.type, false);
    // You can perform any action here
  };

  const debouncedOnStopTyping = useCallback(
    debounce(handleStopTyping, 1000), // Adjust the delay as needed
    [currentUser?._id]
  );

  useEffect(() => {
    setOpenUserDrawer(!matchDownSM);
  }, [matchDownSM]);

  useEffect(() => {
    if (user) {
      getRoomUsers();
    }
  }, [user]);

  useEffect(() => {
    dispatch(openDrawer(false));
    FocusTextInput('');
  }, []);

  useEffect(() => {
    setImageData([]);
  }, [userChats]);

  useEffect(() => {
    return () => {
      if (location.pathname === '/chat' || location.pathname === '/learner/chat') dispatch(resetStateChat());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!(currentUser?._id in activeUser)) {
      dispatch(resetTyping(currentUser?._id));
    }
  }, [activeUser]);

  const typing = useSelector((state) => state.chat.isTyping);

  return (
    <Box
      sx={{
        display: 'flex',
        overflowY: "auto",
        height:
          location.pathname !== '/chat' && location.pathname !== '/learner/chat' ? { lg: '92.2vh', md: '92.2vh', xs: '93.6vh' } : '80vh'
      }}
    >
      <ChatDrawer openChatDrawer={openUserDrawer} handleDrawerOpen={handleUserDrawerChange} />
      <Main theme={theme} open={openUserDrawer}>
        <Grid container sx={{ height: '100%' }}>
          <Grid
            item
            xs={12}
            md={userDetailsPopOver ? 9 : 12}
            xl={userDetailsPopOver ? 9 : 12}
            sx={{
              height: '100%',
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.shorter + 200
              })
            }}
          >
            {userRoomId ? (
              <MainCard
                content={false}
                sx={{
                  bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : 'secondary.lighter',
                  // pt: 2,
                  pl: 2,
                  borderRadius: userDetailsPopOver ? '0' : '0 12px 12px 0',
                  transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.shorter + 200
                  })
                }}
              >
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      bgcolor: theme.palette.background.paper,
                      pr: 2,
                      pb: 2,
                      mt: 3,
                      pt: '16px !important',
                      borderBottom: `1px solid ${theme.palette.divider}`
                    }}
                  >
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <IconButton onClick={handleUserDrawerChange} color="secondary" size="large">
                            <HambergerMenu />
                          </IconButton>
                          {currentUser && (
                            <Avatar
                              alt={currentUser?.isAIChat ? 'Sapphire Bot' : currentUser.firstName || 'User Name'}
                              src={
                                currentUser?.isAIChat
                                  ? sapphireImage
                                  : `data:${currentUser?.userProfilePicId?.mimetype};base64,${currentUser?.userProfilePicId?.file}` ||
                                    avatarImage
                              }
                              size="xl"
                              sx={{
                                width: 47,
                                height: 47,
                                border: '1px solid',
                                borderColor: theme.palette.primary.main,
                                p: 2,
                                bgcolor: 'transparent',
                                objectFit: 'contain',
                                '& .MuiAvatar-img': {
                                  objectFit: 'contain',
                                  height: '88px',
                                  width: '88px',
                                  borderRadius: '50%'
                                }
                              }}
                            />
                          )}
                          <Stack>
                            <Typography variant="subtitle1">
                              {currentUser?.isAIChat ? 'Sapphire Bot' : currentUser.firstName + ' ' + currentUser.lastName || 'User Name'}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              <Chip
                                variant="light"
                                label={currentUser?.isAIChat ? 'Bot' : currentUser.type || 'User Role'}
                                color={currentUser.type === 'admin' ? 'primary' : currentUser.type === 'faculty' ? 'warning' : 'info'}
                                sx={{ mt: 0.5, textTransform: 'capitalize' }}
                                size="small"
                              />
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item>
                        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                          {!userDetailsPopOver && (
                            <IconButton onClick={handleUserPopOverChange} size="large" color={userDetailsPopOver ? 'error' : 'secondary'}>
                              <InfoCircle />
                            </IconButton>
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sx={{ pt: '0px !important' }}>
                    <SimpleBar
                      sx={{
                        overflowX: 'hidden',
                        // height: 'calc(100vh - 560px)',
                        // minHeight: '62vh'
                        height: 'calc(100vh - 430px)',
                        minHeight: 400
                      }}
                    >
                      <Box sx={{ pl: 1, pr: 3 }}>
                        {userSwitch && (
                          <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} height={'100%'} width={'100%'}>
                            <Loader2 />
                          </Stack>
                        )}
                        {!userSwitch && userChats?.length > 0 ? (
                          <ChatHistory textInputRef={FocusTextInput} />
                        ) : (
                          !userSwitch && <Typography>No chat history available</Typography>
                        )}
                      </Box>
                    </SimpleBar>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{ mt: 0, bgcolor: theme.palette.background.paper, borderTop: `1px solid ${theme.palette.divider}` }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                      {imageData?.length > 0 &&
                        imageData.map((imagePreview, index) => (
                          <Box
                            key={index}
                            sx={{
                              my: 0.5,
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}
                          >
                            <Box sx={{ position: 'relative', mr: 2 }}>
                              <Avatar
                                variant="square"
                                src={getIconByFileType(imagePreview?.mimetype) || imagePreview.preview}
                                alt="Preview"
                                style={{
                                  width: '50px',
                                  height: '50px',
                                  borderRadius: '4px'
                                }}
                              />
                              <IconButton sx={{ position: 'absolute', top: -20, right: -20 }} onClick={() => removeImage(index)}>
                                <CloseCircle size="10" />
                              </IconButton>
                            </Box>
                          </Box>
                        ))}
                    </Box>
                    <Stack>
                      {aiChatLoading && userRoomId === AiRoomId && <WaveAnimationDot />}
                      <TextField
                        inputRef={textInput}
                        fullWidth
                        multiline
                        rows={1}
                        placeholder="Your Message..."
                        value={chatMessage ? chatMessage : ''}
                        disabled={aiChatLoading && userRoomId === AiRoomId}
                        onChange={(e) => {
                          if (user?._id && user?.type) {
                            if (!isTyping) {
                              if (!currentUser?.isAIChat) {
                                sendIsTyping(user._id, currentUser?._id, user?.type, currentUser?.type, true);
                              }
                            }
                            setIsTyping(true);
                          }
                          dispatch(setChatMessage(e.target.value));
                          if (!currentUser?.isAIChat) {
                            debouncedOnStopTyping();
                          }
                        }}
                        onKeyDown={(event) => {
                          // Handle Shift + Enter to insert a new line
                          const key = event.key;
                          if (event.shiftKey && key === 'Enter') {
                            event.preventDefault();
                            dispatch(setChatMessage(chatMessage + '\n'));

                            return;
                          }

                          // Handle Enter key to send message
                          if (!event.shiftKey && key === 'Enter') {
                            event.preventDefault(); // Prevent default behavior
                            if (chatMessage?.trim()?.length > 0) {
                              handleOnSend();
                            }
                            return;
                          }
                        }}
                        variant="standard"
                        id="chatTextInput"
                        sx={{
                          pr: 2,
                          '& .MuiInput-root:before': { borderBottomColor: theme.palette.divider }
                        }}
                      />
                      <Typography variant="subtitle2">{typing?.[currentUser?._id] ? `${currentUser?.firstName} is typing` : ''}</Typography>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Stack direction="row" sx={{ ml: -1 }}>
                          <IconButton
                            aria-describedby={emojiId}
                            onClick={handleOnEmojiButtonClick}
                            sx={{ opacity: 0.5 }}
                            size="medium"
                            color="secondary"
                          >
                            <EmojiHappy />
                          </IconButton>
                          <Popper
                            id={emojiId}
                            open={Boolean(anchorElEmoji)}
                            anchorEl={anchorElEmoji}
                            disablePortal
                            style={{ zIndex: 1200 }}
                            placement="top"
                            popperOptions={{
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    // offset: [-20, 125]
                                    offset: [0, -10]
                                  }
                                }
                              ]
                            }}
                          >
                            <ClickAwayListener onClickAway={handleCloseEmoji}>
                              <MainCard elevation={8} content={false}>
                                <EmojiPicker onEmojiClick={onEmojiClick} defaultSkinTone={SkinTones?.DARK} autoFocusSearch={false} />
                              </MainCard>
                            </ClickAwayListener>
                          </Popper>
                          {/* <Button sx={{ opacity: 0.5 }} size="medium" color="secondary" onClick={handleClick}></Button> */}
                          {currentUser?.isAIChat ? (
                            <div>
                              <input
                                accept=".gif, .jpeg, .jpg, .png,"
                                max-size="10485760"
                                id="contained-button-file1"
                                name="interiorPhotos"
                                type="file"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                              />
                              <IconButton onClick={handleButtonClick} aria-label="upload files">
                                <AttachCircle />
                              </IconButton>
                            </div>
                          ) : (
                            <div>
                              <input
                                accept=".gif, .jpeg, .jpg, .png, .pdf, .doc, .docx, .txt"
                                max-size="10485760"
                                id="contained-button-file1"
                                name="interiorPhotos"
                                multiple
                                type="file"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                              />
                              <IconButton onClick={handleButtonClick} aria-label="upload files">
                                <AttachCircle />
                              </IconButton>
                            </div>
                          )}
                        </Stack>
                        <IconButton
                          color="primary"
                          disabled={
                            (chatMessage?.trim()?.length === 0 && imageData?.length === 0) || (aiChatLoading && userRoomId === AiRoomId)
                          }
                          onClick={() => {
                            if (chatMessage?.trim()?.length > 0 || imageData?.length > 0) handleOnSend();
                          }}
                          size="large"
                          sx={{ mr: 1.5 }}
                        >
                          <Send />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </MainCard>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: { lg: 'auto', md: '94.9vh' },
                  height: '100%',
                  border: theme.palette.mode == 'dark' ? '1px solid #29313B' : '1px solid #E8EBEE ',
                  borderRadius: '0px 12px 12px 0px'
                }}
              >
                <Stack
                  spacing={2}
                  alignItems={'center'}
                  sx={{
                    textAlign: 'center'
                  }}
                >
                  <UserSearch size="80" variant="TwoTone" />
                  <Typography variant="h3">
                    <FormattedMessage id="Select user to chat...." />
                  </Typography>
                  {isSmallScreen && (
                    <Button
                      sx={{ width: '150px' }}
                      variant="contained"
                      color="primary"
                      startIcon={<AddCircle />}
                      onClick={() => {
                        setOpenUserDrawer(true);
                      }}
                    >
                      Select user
                    </Button>
                  )}
                </Stack>
              </Box>
            )}
          </Grid>

          <Grid item xs={12} md={3} xl={3} sx={{ overflow: 'hidden', display: userDetailsPopOver ? 'flex' : 'none' }}>
            <Collapse orientation="horizontal" in={userDetailsPopOver && !matchDownMD}>
              <UserDetails onClose={handleUserPopOverChange} />
            </Collapse>
          </Grid>

          <Dialog
            TransitionComponent={PopupTransition}
            onClose={handleUserPopOverChange}
            open={matchDownMD && userDetailsPopOver}
            scroll="body"
          >
            <UserDetails />
          </Dialog>
        </Grid>
      </Main>
    </Box>
  );
};

export default Chat;
