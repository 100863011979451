import { useState, ChangeEvent, MouseEvent } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, IconButton, InputAdornment, OutlinedInput, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material';
import UserList from './UserList';
import MainCard from 'components/MainCard';
import SimpleBar from 'components/third-party/SimpleBar';
import { Star, UserSearch } from 'iconsax-react';
import useChat from 'hooks/useChat';
import useAuth from 'hooks/useAuth';
import { dispatch, useSelector } from 'store';
import {
  removeUnreadCount,
  setChatMessage,
  setChatSkip,
  setCurrentUser,
  setUnreadChip,
  setUserChatId,
  setUserRoomId,
  setUserSearch,
  setUserSwitching
} from 'store/reducers/chat';
import useAIChatOptions from 'hooks/useAIChatOptions';
import { FormattedMessage } from 'react-intl';

interface ChatDrawerProps {
  handleDrawerOpen: () => void;
  openChatDrawer: boolean;
}

const useChatDrawer = () => {
  const { user } = useAuth();
  const { getUsers, searchText, getRoomUsers } = useChat();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [status, setStatus] = useState('available');

  const handleClickRightMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseRightMenu = () => {
    setAnchorEl(null);
  };

  const handleRightMenuItemClick = (userStatus: string) => () => {
    setStatus(userStatus);
    handleCloseRightMenu();
  };

  const fetchUsers = async (searchValue: string) => {
    searchValue != '' && (await getUsers(searchValue as string, user?.organizationId?._id as string));
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(setUserSearch(event.target.value));
    user && fetchUsers(event.target.value);
  };

  return {
    anchorEl,
    status,
    searchText,
    handleClickRightMenu,
    handleCloseRightMenu,
    handleRightMenuItemClick,
    handleSearch
  };
};

interface AIUser {
  isAIChat: boolean;
  roomId: string;
}

const ChatDrawer = ({ handleDrawerOpen, openChatDrawer }: ChatDrawerProps) => {
  const theme = useTheme();
  const { user, organization } = useAuth();
  const { createAIRoom } = useAIChatOptions();
  const { AiRoomId, getRoomChats } = useChat();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const drawerBG = theme.palette.mode === 'dark' ? 'dark.main' : 'white';
  const { searchText, handleSearch } = useChatDrawer();
  const AiUnreadCount = useSelector((state) => state.chat.aiUnreadCount);
  const handleUserClick = async (roomId: string, user: AIUser, count?: number) => {
    dispatch(setChatSkip(1));
    dispatch(setUserChatId(null));
    dispatch(setChatMessage(''));
    dispatch(setUserSwitching(true));
    await getRoomChats(roomId, 1);
    if (count && count > 0) {
      dispatch(removeUnreadCount(roomId));
    }
    dispatch(setUnreadChip(true));
    dispatch(setUserRoomId(roomId));
    dispatch(setCurrentUser(user));
    matchDownLG && handleDrawerOpen();
  };
  return (
    <Drawer
      sx={{
        width: 320,
        // height: 'calc(100vh - 98px)',
        // minHeight: '64vh',
        flexShrink: 0,
        zIndex: { xs: 999999999, lg: 0 },
        '& .MuiDrawer-paper': {
          // height: '100%',
          width: 320,
          boxSizing: 'border-box',
          position: 'relative',
          border: 'none',
          ...(!matchDownLG && {
            borderRadius: '12px 0 0 12px'
          })
        }
      }}
      variant={matchDownLG ? 'temporary' : 'persistent'}
      anchor={'left'}
      open={openChatDrawer}
      ModalProps={{ keepMounted: true }}
      onClose={handleDrawerOpen}
    >
      <MainCard
        sx={{
          bgcolor: matchDownLG ? 'transparent' : drawerBG,
          borderRadius: '12px 0 0 12px',
          borderRight: 'none',
          height: '100%'
        }}
        border
        content={false}
      >
        <Box sx={{ p: 3, pb: 0 }}>
          <Stack spacing={2}>
            <Stack direction="row" spacing={0.5} alignItems="center" justifyContent={'space-between'}>
              <Typography variant="h5" color="inherit">
                <FormattedMessage id="Messages" />
              </Typography>
              {user?.type === 'student' && (
                <Tooltip title={'chat with ai'}>
                  <IconButton
                    sx={{
                      backgroundColor: 'transparent', // Transparent background for icon-only button
                      transition: 'transform 0.3s ease', // Smooth transition
                      '&:hover': {
                        transform: 'scale(1.2)' // Grow effect on hover
                      }
                    }}
                    onClick={() => {
                      if (!AiRoomId) createAIRoom([user?._id as string]);
                      else handleUserClick(AiRoomId, { roomId: AiRoomId, isAIChat: true }, AiUnreadCount);
                    }}
                  >
                    <Star color={theme.palette.primary.main} />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>

            <OutlinedInput
              fullWidth
              placeholder="Search"
              value={searchText}
              onChange={handleSearch}
              // sx={{
              //   '& .MuiOutlinedInput-input': {
              //     p: '10.5px 0px 12px'
              //   }
              // }}
              endAdornment={
                <InputAdornment position="end">
                  <UserSearch size={22} variant="TwoTone" />
                </InputAdornment>
              }
            />
          </Stack>
        </Box>

        <SimpleBar
          sx={{
            overflowX: 'hidden',
            height: matchDownLG ? 'calc(100vh - 120px)' : 'calc(100vh - 428px)',
            minHeight: matchDownLG ? 0 : '79vh'
          }}
        >
          <Box sx={{ p: 3, pt: 0 }}>
            <UserList closeDrawer={handleDrawerOpen} />
          </Box>
        </SimpleBar>
      </MainCard>
    </Drawer>
  );
};

export default ChatDrawer;
