import { useCallback, useEffect, useRef, useState } from 'react';

// material-ui
import { Box, Card, CardContent, Grid, Stack, Typography, IconButton, Link } from '@mui/material';
import { useTheme } from '@mui/system';
import ReactMarkdown from 'react-markdown';
// project-imports
import ChatMessageAction from './ChatMessageAction';
// import IconButton from 'components/@extended/IconButton';

// assets
import sapphireImage from 'assets/images/sapphire_logo.jpg';
import { ArrowCircleDown2, DocumentDownload, Edit, TickCircle } from 'iconsax-react';
import useChat from 'hooks/useChat';
import useAuth from 'hooks/useAuth';
import Avatar from 'components/@extended/Avatar';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setChatLoading, setChatSkip, setProgrammaticScroll, setUnreadChip, setUserChatId, setUserInteracted } from 'store/reducers/chat';
import LazyLoad from './LazyLoad';
import Loader2 from 'components/Loader2';
import { Chip } from '@mui/material';
import pdfIcon from 'assets/images/fileIcons/OF1HA11-ai (2).png';
import textIcon from 'assets/images/fileIcons/OF1HA11-ai (14).png';
import docIcon from 'assets/images/fileIcons/OF1HA11-ai (8).png';
import pptIcon from 'assets/images/fileIcons/OF1HA11-ai (1).png';
import otherIcon from 'assets/images/pdf-file_9680524.png';
import { useSelector } from 'store';
import { useLocation } from 'react-router';
import HideOnScroll from 'components/hideToScroll';

const avatarImage = (require as any).context('assets/images/users', true);

const extractFileName = (filePath: any) => {
  const parts = filePath.split('/');
  const fullFileName = parts[parts.length - 1];
  const nameParts = fullFileName.split(/[-.]/);
  const fileName = nameParts.slice(1, -1).join('-'); // Join parts in case there are multiple hyphens
  const fileType = nameParts[nameParts.length - 1];
  return `${fileName} (${fileType})`;
};

const fileTypeToIcon = {
  'application/pdf': pdfIcon,
  'application/msword': otherIcon,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': docIcon,
  'text/plain': textIcon,
  'application/vnd.ms-powerpoint': pptIcon,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': pptIcon
} as const;

type FileType = keyof typeof fileTypeToIcon;

const getIconByFileType = (fileType: FileType): string => {
  return fileTypeToIcon[fileType] || pdfIcon;
};

const handleDownload = (url: any) => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
const FileComponent = (chat: any) => {
  const imageFiles = chat?.file?.filter((file: any) => file.type.startsWith('image/'));
  const documentFiles = chat?.file?.filter(
    (file: any) =>
      !file.type.startsWith('image/') &&
      [
        'application/pdf',
        'application/msword',
        'application/txt',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/plain',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
      ].includes(file.type)
  );

  return (
    <>
      {imageFiles?.length > 0 && (
        <Grid container spacing={1} m={1}>
          {imageFiles?.map((file: any, index: any) => (
            <Grid
              item
              sm={imageFiles?.length === 1 ? 12 : 6}
              md={imageFiles?.length === 1 ? 12 : imageFiles?.length === 2 ? 6 : 4}
              lg={imageFiles?.length === 1 ? 12 : imageFiles?.length === 2 ? 6 : imageFiles?.length === 3 ? 4 : 4}
              key={index}
            >
              <Box
                sx={{
                  position: 'relative',
                  '&:hover .avatar': {
                    filter: 'blur(1.5px)'
                  },
                  '&:hover .downloadIcon': {
                    display: 'flex'
                  }
                }}
              >
                <Avatar
                  alt={'User Name'}
                  src={process.env.REACT_APP_FILE_URL + file?.url}
                  className="avatar"
                  sx={{
                    margin: 'auto',
                    width: '150px',
                    height: '150px',
                    minHeight: '100px',
                    borderRadius: '8px',
                    userSelect: 'none',
                    pointerEvents: 'none',
                    bgcolor: 'transparent'
                  }}
                />

                <Box
                  className="downloadIcon"
                  sx={{
                    display: 'none',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'rgba(0, 0, 0, 0.5)',
                    borderRadius: '50%'
                  }}
                >
                  <IconButton onClick={() => handleDownload(process.env.REACT_APP_FILE_URL + file?.url)} sx={{ color: 'white' }}>
                    <DocumentDownload size="32" />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
      {documentFiles?.length > 0 && (
        <Grid container spacing={1} m={1}>
          {documentFiles?.map((file: any, index: any) => {
            // const fileInfo = extractFileName(file.url);
            const icon = getIconByFileType(file.type as FileType);
            return (
              <Grid
                item
                sm={documentFiles?.length === 1 ? 12 : 6}
                md={documentFiles?.length === 1 ? 12 : documentFiles?.length === 2 ? 6 : 4}
                lg={documentFiles?.length === 1 ? 12 : documentFiles?.length === 2 ? 6 : documentFiles?.length === 3 ? 4 : 3}
                key={index}
              >
                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                  <Box
                    sx={{
                      // border: 1,
                      // borderColor: 'gray',
                      borderRadius: '16px',
                      position: 'relative',
                      overflow: 'hidden',
                      '&:hover .hoverContent': {
                        opacity: 1,
                        visibility: 'visible'
                      }
                    }}
                  >
                    <Avatar
                      src={icon}
                      alt={'User Name'}
                      sx={{
                        margin: 'auto',
                        width: '120px',
                        height: '120px',
                        minHeight: '100px',
                        borderRadius: '8px',
                        bgcolor: 'transparent',
                        userSelect: 'none',
                        pointerEvents: 'none'
                      }}
                    />
                    <Box
                      className="hoverContent"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: '#4287f5',
                        borderRadius: '0px 0px 14px 14px',
                        px: 1,
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        opacity: 0,
                        visibility: 'hidden',
                        transition: 'opacity 0.3s, visibility 0.3s'
                      }}
                    >
                      <Typography
                        variant="caption"
                        noWrap
                        sx={{
                          maxWidth: '80px',
                          overflow: 'hidden',
                          color: '#ffffff',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {extractFileName(file?.url)}.....pdf
                      </Typography>
                      <IconButton
                        onClick={() => handleDownload(process.env.REACT_APP_FILE_URL + file?.url)}
                        color="inherit"
                        sx={{ mx: 1 }}
                        disableRipple
                      >
                        <ArrowCircleDown2 size="32" />
                      </IconButton>
                    </Box>
                  </Box>
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};

const UnReadChip = ({ unRead }: any) => {
  localStorage.setItem('isReadMessage', 'true');
  const show = useSelector((state) => state.chat.showUnreadChip);
  // useEffect(() => {
  //   // Function to execute after the timeout
  //   const handleTimeout = () => {
  //     dispatch(setUnreadChip(false));
  //     // Perform any actions here, e.g., updating state or calling a function
  //   };

  //   // Set up a timeout to call `handleTimeout` after 3 seconds (3000 milliseconds)
  //   const timeoutId = setTimeout(handleTimeout, 12000);

  //   // Cleanup function to clear the timeout if the component unmounts
  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, []);
  return (
    <>
      {show && (
        <Box
          id={'unread_message'}
          ref={unRead}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Chip
            label={'Unread Messages'}
            sx={{
              background: '#E5E2FC',
              marginBottom: 0.5,
              fontWeight: 'bolder',
              color: '#453B8B'
            }}
          />
        </Box>
      )}
    </>
  );
};

// const Message = ({
//   message,
//   files,
//   id,
//   onRead,
//   handleDownload,
//   isImage,
//   isDeleted
// }: {
//   message: string;
//   id: string;
//   files?: any;
//   handleDownload?: (val: string) => void;
//   onRead: (id: string) => void;
//   isImage?: boolean;
//   isDeleted?: boolean;
// }) => {
//   const messageRef = useRef(null);
//   const [hasBeenRead, setHasBeenRead] = useState(false);
//   const handleIntersection = useCallback(
//     (entries: any) => {
//       entries.forEach((entry: any) => {
//         if (entry.isIntersecting && !hasBeenRead) {
//           // Call the onRead function when the message becomes visible
//           setHasBeenRead(true);
//           onRead(id);
//         }
//       });
//     },
//     [id, onRead, hasBeenRead]
//   );

//   useEffect(() => {
//     const observer = new IntersectionObserver(handleIntersection, {
//       root: null,
//       rootMargin: '0px',
//       threshold: 0.1
//     });

//     if (messageRef.current) {
//       observer.observe(messageRef.current);
//     }

//     return () => {
//       if (messageRef.current) {
//         observer.unobserve(messageRef.current);
//       }
//     };
//   }, [handleIntersection]);

//   return (
//     <div ref={messageRef} className="unRead">
//       {isImage && files?.length > 0 && (
//         <Grid container spacing={1}>
//           <FileComponent file={files} />
//         </Grid>
//       )}

//       <Typography sx={{ minHeight: '2px', wordWrap: 'break-word' }} className="unread" variant="h6" color="textPrimary">
//         {/* Render your message content here */}
//         {/* {isDeleted ? 'This message is deleted' : message} */}
//         {isDeleted ? (
//           <Grid item xs={12}>
//             <Typography sx={{ wordWrap: 'break-word' }} variant="h6" color="textPrimary">
//               This Message Is Deleted.
//             </Typography>
//           </Grid>
//         ) : (
//           <Grid item xs={12}>
//             <ReadMore text={message} maxLength={100} />
//           </Grid>
//         )}
//       </Typography>
//     </div>
//   );
// };

const ReadMore = ({ text, maxLength = 100, isFirstChat }: { text: string; maxLength?: number; isFirstChat?: boolean }) => {
  const [isExpanded, setIsExpanded] = useState(isFirstChat ? true : false);
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  if (text.length <= maxLength) {
    const formattedText = text.replace(/\n/g, '<br />');
    return (
      <Typography
        sx={{ fontSize: { sm: '14px', xs: '12px' } }}
        variant="h6"
        color="textPrimary"
        component="div"
        dangerouslySetInnerHTML={{ __html: formattedText }}
      />
    );
  }
  const location = useLocation();
  return (
    <Typography
      variant="h6"
      color="textPrimary"
      sx={{
        wordWrap: 'break-word',
        maxWidth: location?.pathname !== '/learner/chat' ? '100%' : '70vh',
        fontSize: { sm: '14px', xs: '12px' }
      }}
    >
      <ReactMarkdown>{isExpanded ? text : `${text.substring(0, maxLength)}...`}</ReactMarkdown>
      <Link component="button" variant="body2" onClick={handleToggle} style={{ textDecoration: 'none' }}>
        &nbsp;&nbsp;
        {isExpanded ? 'Read Less' : ' Read More'}
      </Link>
    </Typography>
  );
};

const ChatHistory = ({ textInputRef, innerChat }: any) => {
  const { user } = useAuth();
  const [startLazy, setStartLazy] = useState(false);
  const [prevLength, setPrevLength] = useState(0);
  const [newMessage, setNewMessage] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const unRead: any = useRef();
  const bottomTrigger = useRef();
  const { userChats, currentUser, readAllChats, readChat, programmaticScroll, userInteracted, skip, count, loading, roomUsers } = useChat();
  const wrapper = useRef(document.createElement('div'));
  const el = wrapper.current;
  const scrollableRef: any = useRef(null);

  const scrollToBottom = useCallback(() => {
    el.scrollIntoView(false);
    dispatch(setProgrammaticScroll(true));
  }, [el]);

  const handleUpdateMessage = (chatId: string, chatText: string) => {
    textInputRef(chatText);
    dispatch(setUserChatId(chatId));
  };

  const scrollToBottom1 = useCallback(() => {
    if (scrollableRef.current && !newMessage) {
      scrollableRef.current.scrollIntoView(false);
      dispatch(setProgrammaticScroll(true));
    } else {
      setNewMessage(false);
    }
  }, [scrollableRef.current]);

  const parent1 = el.parentElement;
  const parent2 = parent1 ? parent1.parentElement : null;
  const parent3 = parent2 ? parent2.parentElement : null;

  const handleLength = () => {
    if (!loading && roomUsers) {
      dispatch(setChatLoading(true));
      dispatch(setChatSkip(skip + 1));
    }
  };

  localStorage.setItem('isReadMessage', 'false');

  // const readMessage = (chatId: string) => {
  //   dispatch(setReadChats(chatId));
  //   readChat([chatId]);
  // };

  useEffect(() => {
    const handleInteraction = () => {
      if (!programmaticScroll) {
        dispatch(setUserInteracted(true));
      } else {
        dispatch(setUserInteracted(false));
        dispatch(setProgrammaticScroll(false));
      }
    };

    if (parent3) {
      parent3.addEventListener('scroll', handleInteraction);
    }
    window.addEventListener('scroll', handleInteraction);

    if (parent2 && parent1) {
      parent2.addEventListener('scroll', handleInteraction);
      parent1.addEventListener('scroll', handleInteraction);
    }
    return () => {
      window.removeEventListener('scroll', handleInteraction);
      if (parent2 && parent1) {
        parent2.removeEventListener('scroll', handleInteraction);
        parent1.removeEventListener('scroll', handleInteraction);
      }
      if (parent3) {
        parent3.removeEventListener('scroll', handleInteraction);
      }
    };
  }, [el, parent1, parent2, userInteracted, programmaticScroll]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(setProgrammaticScroll(false));
    }, 1000);
    return () => clearTimeout(timeout);
  }, [programmaticScroll]);

  useEffect(() => {
    if (prevLength + 1 === userChats.length) {
      setNewMessage(true);
      scrollToBottom();
    } else {
      if (skip !== 1) scrollToBottom1();
    }
    setPrevLength(userChats.length);
  }, [userChats?.length, scrollToBottom1]);
  useEffect(() => {
    readAllChats();
    const timer = setTimeout(() => {
      setStartLazy(true);
    }, 3000); //3 seconds

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!startLazy && unRead.current) {
      unRead.current.scrollIntoView(false);
    } else {
      scrollToBottom();
    }
  }, [userChats.length, scrollToBottom]);

  localStorage.setItem('isReadMessage', 'false');

  const extractFileName = (filePath: any) => {
    const parts = filePath.split('/');
    const fullFileName = parts[parts.length - 1];
    const nameParts = fullFileName.split(/[-.]/);
    return nameParts[1];
  };
  return (
    <>
      <Grid container spacing={2.5} ref={wrapper} sx={{ marginTop: innerChat ? '50px' : 'auto' }}>
        {startLazy && userInteracted && count > userChats?.length && (
          <Grid item xs={12} key={0} id={'loading'} sx={{ mb: 3, mt: innerChat ? '50px' : 'auto' }}>
            {!loading && count > userChats.length ? <LazyLoad handleLength={handleLength} /> : count > userChats?.length && <Loader2 />}
          </Grid>
        )}
        {userChats.map((chat: any, index: number) => {
          let chatIndex = userChats.length % 20;
          if (chatIndex === 0) {
            chatIndex = 21;
          }
          const isRead = localStorage.getItem('isReadMessage');
          if (!chat?.isRead) {
            localStorage.setItem('isReadMessage', 'true');
          }
          return (
            <Grid
              item
              xs={12}
              key={chat?._id}
              className={index === chatIndex - 1 ? 'scrollableRef' : ''}
              ref={index === chatIndex - 1 ? scrollableRef : undefined}
            >
              {chat?.senderId?._id === user?._id ? (
                <Stack
                  spacing={1.25}
                  direction="row"
                  // sx={{ marginRight: chat?.text?.split(/\s+/).some((word: string) => word.length > 80) ? '40px' : '0px' }}
                >
                  <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item xs={2} md={3} xl={4} />
                    <Grid item xs={10} md={9} xl={8}>
                      <Stack direction="row" justifyContent="flex-end" alignItems="flex-end">
                        {!chat?.isDeleted && (
                          <>
                            <ChatMessageAction chatMessage={chat?.text} chatId={chat?._id} filesInChat={chat?.file?.length === 0} />
                            {chat?.file?.length === 0 && !currentUser?.isAIChat && (
                              <IconButton size="small" color="secondary" onClick={() => handleUpdateMessage(chat?._id, chat?.text)}>
                                <Edit />
                              </IconButton>
                            )}
                          </>
                        )}
                        <Card
                          sx={{
                            display: 'inline-block',
                            // float: 'right',
                            // bgcolor: chat?.isDeleted ? theme.palette.error.lighter : theme.palette.primary.main,
                            bgcolor:
                              theme.palette.mode === 'light'
                                ? chat?.isDeleted
                                  ? theme.palette.error.lighter
                                  : theme.palette.primary.lighter
                                : 'background.background',
                            boxShadow: 'none',
                            ml: 1
                          }}
                        >
                          <CardContent sx={{ p: 1.2, pb: '8px !important' }}>
                            {chat?.isDeleted ? (
                              <Grid item xs={12}>
                                <Typography
                                  sx={{ wordWrap: 'break-word', fontSize: { sm: '14px', xs: '12px' } }}
                                  variant="h6"
                                  color="textPrimary"
                                >
                                  {chat?.isDeleted ? 'This Message Is Deleted.' : chat.text}
                                </Typography>
                              </Grid>
                            ) : (
                              <>
                                {chat?.file?.length > 0 && (
                                  <Grid container spacing={1}>
                                    {/* {chat.file.map((file: any, index: any) => ( */}
                                    <FileComponent file={chat?.file} />
                                    {/* ))} */}
                                  </Grid>
                                )}
                                {chat?.text && (
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="h6"
                                      sx={{ wordWrap: 'break-word', fontSize: { sm: '14px', xs: '12px' } }}
                                      color={chat?.isDeleted ? '#DC2626' : 'textPrimary'}
                                    >
                                      {chat?.isDeleted ? (
                                        <Grid item xs={12}>
                                          <Typography
                                            sx={{ wordWrap: 'break-word', fontSize: { sm: '14px', xs: '12px' } }}
                                            variant="h6"
                                            color="textPrimary"
                                          >
                                            This Message Is Deleted.
                                          </Typography>
                                        </Grid>
                                      ) : (
                                        <Grid item xs={12}>
                                          <ReadMore text={chat?.text} isFirstChat={chat?.isFirstMessage} maxLength={100} />
                                        </Grid>
                                      )}
                                    </Typography>
                                  </Grid>
                                )}
                              </>
                            )}
                          </CardContent>
                        </Card>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction={'row-reverse'}>
                        <Stack direction={'row'} alignItems={'center'}>
                          <Typography align="right" variant="subtitle2" color="textSecondary" sx={{ mr: '5px' }}>
                            {chat?.isEdited ? 'Edited ' + moment(chat?.updatedAt).fromNow() : moment(chat?.createdAt).fromNow()} {}
                          </Typography>
                          {chat.isRead && <TickCircle size={14} color="green" />}{' '}
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Avatar
                    alt={user?.firstName || 'User Name'}
                    src={
                      typeof user?.userProfilePicId !== 'string' && user?.userProfilePicId?.path
                        ? process.env.REACT_APP_FILE_URL + user?.userProfilePicId?.path
                        : typeof user?.userProfilePicId !== 'string' && user?.userProfilePicId?.mimetype
                        ? `data:${user?.userProfilePicId?.mimetype};base64,${user?.userProfilePicId?.file}`
                        : avatarImage
                    }
                    size="xl"
                    sx={{
                      width: 34,
                      height: 34,
                      mr: 2,
                      border: '1px solid',
                      borderColor: theme.palette.primary.main,
                      p: 2,
                      bgcolor: 'transparent',
                      objectFit: 'contain',
                      marginRight: '7px',
                      '& .MuiAvatar-img': {
                        // height: '88px',
                        objectFit: 'contain',
                        // width: '88px',
                        width: 34,
                        height: 34,
                        borderRadius: '50%'
                      }
                    }}
                  />
                </Stack>
              ) : (
                <>
                  {!chat?.isRead && isRead === 'false' && <UnReadChip unRead={unRead} />}
                  <Stack direction="row" spacing={1.25} alignItems="flex-start">
                    <Avatar
                      alt={currentUser?.isAIChat ? 'Sapphire Bot' : currentUser?.firstName || 'User Name'}
                      src={
                        currentUser?.isAIChat
                          ? sapphireImage
                          : `data:${currentUser?.userProfilePicId?.mimetype};base64,${currentUser?.userProfilePicId?.file}` || avatarImage
                      }
                      size="xl"
                      sx={{
                        width: 34,
                        height: 34,
                        border: '1px solid',
                        borderColor: theme.palette.primary.main,
                        p: 2,
                        bgcolor: 'transparent',
                        objectFit: 'contain',
                        marginRight: '7px',
                        '& .MuiAvatar-img': {
                          objectFit: 'contain',
                          width: 34,
                          height: 34,
                          borderRadius: '50%'
                        }
                      }}
                    />
                    <Grid container>
                      <Grid item xs={12} sm={7}>
                        <Card
                          sx={{
                            display: 'inline-block',
                            float: 'left',
                            // bgcolor: theme.palette.mode === 'dark' ? 'background.background' : 'common.white',
                            bgcolor:
                              theme.palette.mode === 'light'
                                ? chat?.isDeleted
                                  ? theme.palette.error.lighter
                                  : theme.palette.warning.lighter
                                : 'background.background',

                            boxShadow: 'none'
                          }}
                        >
                          <CardContent sx={{ p: 1.2, pb: '8px !important' }}>
                            {chat?.isDeleted ? (
                              <Grid item xs={12}>
                                <Typography
                                  sx={{ wordWrap: 'break-word', fontSize: { sm: '14px', xs: '12px' } }}
                                  variant="h6"
                                  color="textPrimary"
                                >
                                  {chat?.isDeleted ? 'This Message Is Deleted.' : chat.text}
                                </Typography>
                              </Grid>
                            ) : (
                              <>
                                {chat?.file?.length > 0 && (
                                  <Grid container spacing={1}>
                                    {/* {chat.file.map((file: any, index: any) => ( */}
                                    <FileComponent file={chat?.file} />
                                    {/* ))} */}
                                  </Grid>
                                )}
                                {chat?.text && (
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="h6"
                                      sx={{ wordWrap: 'break-word' }}
                                      color={chat?.isDeleted ? '#DC2626' : 'textPrimary'}
                                    >
                                      {chat?.isDeleted ? (
                                        <Grid item xs={12}>
                                          <Typography
                                            sx={{ wordWrap: 'break-word', fontSize: { sm: '14px', xs: '12px' } }}
                                            variant="h6"
                                            color="textPrimary"
                                          >
                                            This Message Is Deleted.
                                          </Typography>
                                        </Grid>
                                      ) : (
                                        <Grid item xs={12}>
                                          <ReadMore text={chat?.text} maxLength={100} isFirstChat={chat?.isFirstMessage} />
                                        </Grid>
                                      )}
                                    </Typography>
                                  </Grid>
                                )}
                              </>
                            )}
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '5px' }}>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                          {/* {chat.isRead && (
                          <>
                            <TickCircle size={14} color="grey" />
                            &nbsp;&nbsp;
                          </>
                        )} */}
                          <Typography align="left" variant="subtitle2" color="textSecondary">
                            {chat?.isEdited ? 'Edited ' + moment(chat?.updatedAt).fromNow() : moment(chat?.createdAt).fromNow()} {}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>
                  {index === userChats.length - 1 && <Box sx={{ mb: 1 }} className="bottom" ref={bottomTrigger} />}
                </>
              )}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default ChatHistory;
