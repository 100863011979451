// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { CardEdit, ClipboardText, Messages } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  icon1: ClipboardText
};
// ==============================|| MENU ITEMS - CHARTS & MAPS ||============================== //

const PlanLearning: NavItemType = {
  id: 'group-plan-learning',
  type: 'group',
  icon: icons.icon1,
  name: 'Chats',
  children: [
    {
      icon: icons.icon1,
      id: 'plan-learning',
      title: <FormattedMessage id="Plan Learning" />,
      type: 'item',
      url: '/plan-learning'
    }
  ]
};

export default PlanLearning;
