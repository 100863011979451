// third-party
import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  error: null,
  aiRoomId: null,
  user: {},
  roomId: '',
  showUnreadChip: true,
  aiUnreadCount: 0,
  chatId: null,
  programmaticScroll: false,
  userInteracted: false,
  chatLoading: false,
  skip: 1,
  isTyping: null,
  totalChatCount: 0,
  userSwitch: false,
  firstLoad: true,
  search: '',
  userChats: [],
  roomUsers: [],
  orgUsers: [],
  files: [],
  activeUser: {},
  chatMessage: null,
  AILoading: false,
  aiChatRooms: [],
  currentChatRoom: null,
  aiChatHistory: [],
  hasMoreChat: true
};

const chat = createSlice({
  name: 'chat',
  initialState,
  reducers: {

    resetRelatedStatesOnRoomChange(state) {
      state.skip = initialState.skip 
      state.hasMoreChat = initialState.hasMoreChat 
      state.aiChatHistory = initialState.aiChatHistory 
      state.currentChatRoom = initialState.currentChatRoom 
    },

    setAIChatRooms(state, action) {
      state.aiChatRooms = action.payload;
    },

    updateAIChatRoomName(state, action) {
      const { roomId, newRoomName } = action.payload;
    
      state.aiChatRooms = state.aiChatRooms.map((room: any) => 
        room.roomId === roomId ? { ...room, roomName: newRoomName } : room
      );
    },

    deleteAIChatRoom(state, action) {
      const { roomId } = action.payload;
      state.aiChatRooms = state.aiChatRooms.filter((room: any) => room.roomId !== roomId);
    },

    addNewAIChatRoom(state, action) {
      state.aiChatRooms = [action.payload, ...state.aiChatRooms];
    },
    
    setCurrentChatRoom(state, action) {
      state.currentChatRoom = action.payload;
    },

    setAiChatHistory(state, action) {
      state.aiChatHistory = action.payload; // Sets initial chat history array
    },
    
    appendAiChatMessage(state, action) {
      state.aiChatHistory.push(action.payload); // Appends a single message
    },

    setHasMoreChat(state, action) {
      state.hasMoreChat = action.payload
    },

    setChatMessage(state, action) {
      state.chatMessage = action.payload;
    },
    setAiLoading(state, action) {
      state.AILoading = action.payload;
    },
    setAIRoomId(state, action) {
      state.aiRoomId = action.payload;
    },
    setAiUnreadCount(state, action) {
      state.aiUnreadCount = action.payload;
    },
    setActiveUser(state, action) {
      state.activeUser = action.payload;
    },
    resetTyping(state, action) {
      state.isTyping = {
        ...state.isTyping,
        [action.payload]: false
      };
    },
    setIsTyping(state, action) {
      const { senderId, isTyping } = action.payload;
      state.isTyping = {
        ...state.isTyping,
        [senderId]: isTyping
      };
    },
    setCurrentUser(state, action) {
      state.user = action.payload;
    },
    setUnreadChip(state, action) {
      state.showUnreadChip = action.payload;
    },
    setUserInteracted(state, action) {
      state.userInteracted = action.payload;
    },

    setFirstLoad(state, action) {
      state.firstLoad = action.payload;
    },

    setProgrammaticScroll(state, action) {
      state.programmaticScroll = action.payload;
    },

    setChatLoading(state, action) {
      state.chatLoading = action.payload;
    },

    setReadChats(state, action) {
      // let data = state.userChats.map((chat: any) => {
      //   if (chat._id === action.payload) {
      //     return { ...chat, isRead: true };
      //   }
      //   return chat;
      // });
      state.userChats = state.userChats;
    },

    setUserSwitching(state, action) {
      state.userSwitch = action.payload;
    },

    setTotalChatCount(state, action) {
      state.totalChatCount = action.payload;
    },

    addChatCount(state) {
      state.totalChatCount = state.totalChatCount + 1;
    },

    setChatSkip(state, action) {
      state.skip = action.payload;
    },

    setUserRoomId(state, action) {
      state.roomId = action.payload;
    },

    setUserChatId(state, action) {
      state.chatId = action.payload;
    },

    setUserSearch(state, action) {
      state.search = action.payload;
    },

    getOrgUser(state, action) {
      state.orgUsers = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    getRoomUser(state, action) {
      state.roomUsers = action.payload;
    },
    removeUnreadCount(state, action) {
      let data = state.roomUsers.map((roomUser: any) => {
        if (roomUser?._id === action.payload) {
          return { ...roomUser, unreadChatCount: 0 };
        }
        return roomUser;
      });
      state.roomUsers = data;
    },

    setReduceChatCount(state, action) {
      let data = state?.roomUsers?.map((roomUser: any) => {
        if (roomUser?._id === state.roomId) {
          return { ...roomUser, unreadChatCount: roomUser.unreadChatCount - 1 };
        }
        return roomUser;
      });

      state.roomUsers = data;
      const chatData = state.userChats.map((chat: any) => {
        if (action.payload.includes(chat._id)) {
          return { ...chat, isRead: true };
        }
        return chat;
      });
      state.userChats = chatData;
    },

    addChatDrawerCount(state, action) {
      let updatedUsers = state?.roomUsers?.map((roomUser: any) => {
        if (roomUser?._id === action.payload) {
          return { ...roomUser, unreadChatCount: roomUser.unreadChatCount + 1 };
        }
        return roomUser;
      });
      const userIndex = updatedUsers.findIndex((user: { _id: string }) => user._id === action.payload);

      if (userIndex !== -1) {
        const [updatedUser] = updatedUsers.splice(userIndex, 1);

        if (state.aiRoomId) {
          updatedUsers.splice(1, 0, updatedUser);
        } else {
          updatedUsers.unshift(updatedUser);
        }
      }

      // Update the state with the reordered list
      state.roomUsers = updatedUsers;
    },

    // GET USER CHATS
    getUserChats(state, action) {
      let chatsToSave = action.payload;
      chatsToSave?.filter((chat: any) => chat.roomId !== state.roomId);
      state.userChats = chatsToSave;
    },
    increaseUserChats(state, action) {
      state.userChats = [...state.userChats, action.payload];
      const userIndex = state?.roomUsers?.findIndex((user: { _id: string }) => user._id === state.roomId);

      if (userIndex !== -1 && userIndex > 1) {
        let updatedUsers = state?.roomUsers;
        const [updatedUser] = updatedUsers.splice(userIndex, 1);

        if (state.aiRoomId) {
          updatedUsers.splice(1, 0, updatedUser);
        } else {
          updatedUsers.unshift(updatedUser);
        }
        state.roomUsers = updatedUsers;
      }

      // Update the state with the reordered list
    },
    resetStateChat: (state) => {
      return initialState;
    }
  }
});

export const {
  setActiveUser,
  hasError,
  setCurrentUser,
  setChatSkip,
  setUserSwitching,
  setUserInteracted,
  addChatDrawerCount,
  addChatCount,
  setTotalChatCount,
  setAiUnreadCount,
  increaseUserChats,
  setFirstLoad,
  setChatLoading,
  setReadChats,
  setUserRoomId,
  setUserChatId,
  setUserSearch,
  setReduceChatCount,
  getRoomUser,
  removeUnreadCount,
  setUnreadChip,
  setProgrammaticScroll,
  getUserChats,
  resetTyping,
  getOrgUser,
  resetStateChat,
  setAIRoomId,
  setChatMessage,
  setIsTyping,
  setAiLoading,
  setAIChatRooms,
  setCurrentChatRoom,
  addNewAIChatRoom,
  setAiChatHistory,
  appendAiChatMessage,
  setHasMoreChat,
  resetRelatedStatesOnRoomChange,
  updateAIChatRoomName,
  deleteAIChatRoom
} = chat.actions;

export default chat.reducer;
