import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Container, Toolbar } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from './Header';
import Footer from './Footer';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import userType from 'menu-items';
import useConfig from 'hooks/useConfig';
import { dispatch, useSelector } from 'store';
import { openDrawer } from 'store/reducers/menu';
import { LAYOUT_CONST } from 'types/config';
import { DRAWER_WIDTH } from 'config';
import useAuth from 'hooks/useAuth';
import ChatOptions from 'components/chatDrawer/ChatOptionsMain';
import useAIChatOptions from 'hooks/useAIChatOptions';
import {
  removeUnreadCount,
  setChatMessage,
  setChatSkip,
  setCurrentUser,
  setUnreadChip,
  setUserChatId,
  setUserRoomId,
  setUserSwitching
} from 'store/reducers/chat';
import useChat from 'hooks/useChat';
import { Image } from 'types/auth';

// ==============================|| MAIN LAYOUT ||============================== //

interface User {
  _id: string;
  firstName: string;
  lastName: string;
  type: string;
  isLoggedIn: boolean;
  unReadMessage?: string;
  isOnline?: boolean;
  userProfilePicId: Image;
  isAIChat?: boolean;
  roomId?: string;
}

interface AIUser {
  isAIChat: boolean;
  roomId: string;
}

const MainLayout = ({ hideDrawer, isStudent }: { hideDrawer: boolean; isStudent: boolean }) => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  const { createAIRoom } = useAIChatOptions();
  const { getRoomChats } = useChat();
  const { container, miniDrawer, menuOrientation } = useConfig();
  const AiRoomId = useSelector((state) => state.chat.aiRoomId);
  const AiUnreadCount = useSelector((state) => state.chat.aiUnreadCount);
  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;
  const { drawerOpen } = useSelector((state) => state.menu);
  const { user }: any = useAuth();
  const location = useLocation();
  const permissions = user?.userTypeId?.permission;
  const itemData = userType?.[user?.type as 'student'];
  const navigation = {
    items: itemData
  };
  const [open, setOpen] = useState(!miniDrawer || drawerOpen);
  const [openAiChat, setOpenAiChat] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };
  const handleUserClick = async (roomId: string, user: User | AIUser, count?: number) => {
    dispatch(setChatSkip(1));
    dispatch(setUserChatId(null));
    dispatch(setChatMessage(''));
    dispatch(setUserSwitching(true));
    await getRoomChats(roomId, 1);
    if (count && count > 0) {
      dispatch(removeUnreadCount(roomId));
    }
    dispatch(setUnreadChip(true));
    dispatch(setUserRoomId(roomId));
    dispatch(setCurrentUser(user));
    (matchDownLG || location.pathname !== '/learner/chat') && setOpenAiChat(true);
  };
  const handleAiChat = () => {
    if (!AiRoomId) {
      createAIRoom([user?._id as string]);
      setOpenAiChat(true);
    } else handleUserClick(AiRoomId, { roomId: AiRoomId, isAIChat: true }, AiUnreadCount);
  };
  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!matchDownLG);
      dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }
    // eslint-disable-next-line
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line
  }, [drawerOpen]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <style>
        {`.MuiContainer-root{
        padding-left:0px !important;
        padding-right:0px !important
      }`}
      </style>
      <Box sx={{ marginBottom: 7, backgroundColor: '#063B9A' }}>
        <Header
          open={open}
          handleDrawerToggle={handleDrawerToggle}
          hideDrawer={hideDrawer}
          isStudent={isStudent}
          handleAiChat={handleAiChat}
        />
      </Box>
      <Box
        component="main"
        sx={{
          mt: user?.type === 'student' ? { xs: 4.5, sm: 4, md: 4 } : 2,
          width: `calc(100% - ${DRAWER_WIDTH}px)`,
          flexGrow: 1,
          p:{ xs: 2, sm: 3 }
        }}
      >
        <Toolbar sx={{ mt: isHorizontal ? 8 : 'inherit' }} />
        <Container
          maxWidth="lg"
          sx={{
            ...(container && { px: { xs: 0, sm: 2 } }),
            maxWidth: '1600px !important',
            position: 'relative',
            minHeight: 'calc(100vh - 110px)',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
          {permissions?.Chats?.View && location.pathname !== '/learner/test' && location.pathname !== '/learner/quiz/test' && (
            <ChatOptions openAiChat={openAiChat} setOpenAiChat={setOpenAiChat} />
          )}
          <Outlet />
          <Footer />
        </Container>
      </Box>
    </Box>
  );
};

export default MainLayout;
